import { User } from "oidc-client-ts";

import { getConfig } from "../runtime-config";
import { userManager } from "../user-manager";

type IdTokenClaims = {
  aud: string;
  auth_time: number;
  birthdate: string;
  exp: number;
  family_name: string;
  iat: number;
  id_status: string;
  identity_proofing_level: string;
  iss: string;
  jti: string;
  nhs_number: string;
  sub: string;
  surname: string;
  token_use: string;
  vot: string;
  vtm: string;
};

type TokenResponseBody = {
  access_token: string;
  decoded_id_token: IdTokenClaims;
  expires_in: number;
  id_token: string;
  refresh_token: string;
  scope: string;
  token_type: string;
};

const { AUTH_TOKEN_URL } = getConfig();

export async function signInWithNhs(): Promise<User> {
  const urlParameters = new URLSearchParams(globalThis.location.search);
  const code = urlParameters.get("code");

  if (!code) {
    throw new ReferenceError('Missing query parameter: "code"');
  }

  const query = new URLSearchParams({
    code,
    grant_type: "authorization_code",
  });

  const response = await fetch(
    `${AUTH_TOKEN_URL}?redirect_uri=${globalThis.location.origin}/login`,
    {
      body: query.toString(),
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      method: "POST",
    },
  );

  const token = (await response.json()) as TokenResponseBody & {
    id_token_claims: IdTokenClaims;
  };

  const user = new User({
    access_token: token.access_token,
    id_token: token.id_token,
    profile: token.id_token_claims,
    refresh_token: token.refresh_token,
    scope: token.scope,
    token_type: token.token_type,
  });

  await userManager.storeUser(user);

  return user;
}
