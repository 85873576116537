import { signInWithNhs } from "./auth/nhs-login";
import { getConfig } from "./runtime-config";
import { userManager } from "./user-manager";

type UserState = {
  locationState?: { pathname: string; historyState: unknown };
};

const isLoginRoute = globalThis.location.pathname === "/login";
const isSilentLoginRoute = globalThis.location.pathname === "/login/silent";
const isLogoutRoute = globalThis.location.pathname === "/logout";
const isLogoutCallbackRoute =
  globalThis.location.pathname === "/logout/callback";

const { AUTH_IDENTITY_PROVIDER, WEBSITE_URL } = getConfig();

const referrerKey = "referrer";

void (async function () {
  const referrer = globalThis.document.referrer;

  if (referrer.includes("waitcure.com") || referrer.includes("zymego.com")) {
    globalThis.localStorage.setItem(referrerKey, referrer);
  }

  if (globalThis.location.href.includes("hostname")) {
    const searchParameters = new URLSearchParams(globalThis.location.search);
    const clinicName = searchParameters.get("hostname")?.split(".").slice(0, 1);
    if (clinicName) {
      globalThis.location.replace(`bookings/new?clinicName=${clinicName}`);
    }
    return;
  }

  await userManager.clearStaleState();
  let user: Awaited<ReturnType<typeof userManager.getUser>> | void =
    await userManager.getUser();

  if (isLogoutRoute) {
    userManager.signoutRedirect();

    return;
  }

  if (isLogoutCallbackRoute) {
    userManager.signoutCallback();
    const url = globalThis.localStorage.getItem(referrerKey) ?? WEBSITE_URL;
    globalThis.localStorage.removeItem(referrerKey);
    globalThis.location.replace(url);
    return;
  }

  if (isLoginRoute || isSilentLoginRoute) {
    try {
      user =
        AUTH_IDENTITY_PROVIDER === "nhs"
          ? await signInWithNhs()
          : await userManager.signinCallback();

      if (isSilentLoginRoute) {
        return;
      }
    } catch (error) {
      console.error(error);

      if (
        typeof error === "object" &&
        error &&
        (("error" in error && error.error === "IDP-3200") ||
          ("state" in error && error.state === "retry"))
      ) {
        // TODO: We should probably render/redirect to some error view instead of just redirecting to the website.
        await userManager.clearStaleState();

        const url = globalThis.localStorage.getItem(referrerKey) ?? WEBSITE_URL;
        globalThis.localStorage.removeItem(referrerKey);
        globalThis.location.replace(url);
        return;
      }

      void userManager.signinRedirect({ state: "retry" });
      return;
    }
  }

  if (user) {
    void import("./init");

    const userState = user.state as UserState | undefined;
    const locationState = userState?.locationState;

    globalThis.history.replaceState(
      locationState?.historyState,
      "",
      locationState?.pathname ?? "/",
    );
  } else {
    const locationState = {
      pathname: globalThis.location.pathname + globalThis.location.search,
      historyState: globalThis.history.state,
    };

    void userManager.signinRedirect({
      state: { locationState },
    });
  }
})();
